/* * {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
  }
  
  .container {
    margin: 1rem;
    text-align: center;
  }
  
  .nav {
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
  }
  
  .site-title {
    font-size: 2rem;
  }
  
  .nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
  }
  
  .nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
  }
  
  .nav li.active {
    background-color: #555;
  }
  
  .nav li:hover {
    background-color: #777;
  } */








  nav{
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a{
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

nav a:hover{
    background: rgba(0, 0, 0, 0.3);
}

nav a.active{
    background: var(--color-bg);
    color: var(--color-white);
}