/* .banner1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%; 
  margin : 50px;
  grid-column-gap: 20px;
  
}
.founder1 p{
  margin-left:35px;
}

.banner1 img {
  height : 302px;
  width: 255px;
} */

.founder1 {
 transition: 1s ease;
  margin: 35px;
}
.founder1:hover{
-webkit-transform: scale(1.2);
-ms-transform: scale(1.2);
transform: scale(1.2);
transition: 1s ease;
}

/* 
.img2{
  margin-left: 30px;
}

.img1{
  margin-left: 180px;
}
.img3{
  margin-left:-130px
}
#img__txt1{
  margin-left:230px;
}
#img__txt2{
  margin-left:100px;
}
#img__txt3{
  margin-left:-50px;
} */


.portfolio__container{
  display:grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 2.5rem;
}
.portfolio__item{
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

/* .portfolio__item:hover{
  border-color: var(--color-primary-variant);
  background: transparent;
} */

.portfolio__itm-image{
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3{
  margin: 1.2rem 0 2rem;
}

.portfolio__item-cta{
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.fName{
  display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1024px) {
  .portfolio__container{
      grid-template-columns: 1fr 1fr;
      gap: 1.2rem;
  }
}
@media screen and (max-width: 600px) {
  .portfolio__container{
      grid-template-columns: 1fr;
      gap: 1rem;
  }
}


