@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700;900&family=Poppins:wght@300;400;500&display=swap');

/* .outerForAbout{
  width: 100vw;
  height: 100vh;
  position: absolute;
} */
.banner{
  width: 100%;
  height: 33%;
  font-size: 100px;
  font-weight: 1000;
  color: #DEE6F2;
}

.container__about{
  width: 100vw;
  height: 100%;  
  position: absolute;
}

.marquee__txts{
  /* font-size: 100px;
  font-weight: 1000;
  color: #DEE6F2;
  width: 100%;
  height: 33%; */
  
  /* font-size: 200px;
  font-weight: 1000;
  width: 100%;
  height: 33%;
  color: #DEE6F2;
  margin: 0; */
}

/* .txt{
  font-size: 25px;
  width: 100%;
  
} */
/* .marquee__txt{
  padding-top: 0%;
  height: 400;
} */
marquee{
  align-items: center;
  padding: 0;
}

.demo{
  height: 33%;
}
.outerForHead{
 height: 80vh;
}

.txt{
  height: 33%;
  width: 100%;
  justify-content: center;
}
/* .txt1{
  padding: 20px;
}

.txt2{
  padding-top:100px;
  padding-left: 300px;
  padding-bottom:100px;
} */

/* .sec{
  height: 80vh;
} */




@media screen and (max-width: 1050px) {
  .outerForAbout{
      height: 120vh;
      width: 100vw;
  }
  .sec{
    margin-top: 180px;
  }
}

@media screen and (min-width: 600px) {
  .txt{
    font-size: 30px;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
  }
}