.wrapper{
    display: inline-flex;
}

.wrapper .dynamic-txts{
    margin-left: 15px;
    height: 90px;
    line-height: 90px;
    overflow: hidden;
}


.dynamic-txts li{
    list-style: none;
    font-size: 100px;
    font-weight: 1000;
    position: relative;
    top: 0px;
    animation: slide 3s steps(1);
    animation-iteration-count: 1;
  }

  @keyframes slide{
    100%{
      top:-270px;
    }
  }

  .dynamic-txts span{
    text-align: center;
    position: relative;
  }
  .dynamic-txts span::after{
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background: white;
    border-left: 0px solid;
    animation: typing 3s steps(10);
    animation-iteration-count: 1;
    animation-timing-function: linear;
  }
  
  @keyframes typing{
    100%{
      left: 100%;
      margin: 0 -35px 0 35px;
    }
  }

  .loader{
    position: absolute;
    top: 0;
    background-color: white;
    width: 100%;
    height: 100vh;
    z-index: 99;
    left: -100%;
    transition: .5s;
  }
  .header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

  .page2{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #35455D;
    display: none;
    min-height: 90vh;
  }
  #p2{
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 100px;
    color: black;
  }
  .img__class{
    position: absolute;
    left: 50%;
    right: 50%;
    height: 210vh;
    width: 99vw;
    transform: translate(-50%, -50%);
  }
  .logo__class{
    width: 300px;
    height: 300px;
    margin-left: 1000px;
    margin-top: -5px;
  }
  .wrapper1{
    display: inline-flex;
  }
  .wrapper1 .dynamic-txts1{
    margin-left: 15px;
    height: 90px;
    line-height: 90px;
    overflow: hidden;
  }
  .dynamic-txts1 li{
    list-style: none;
    font-size: 40px;
    font-weight: 1000;
    position: relative;
    top: 0px;
    color: white;
    animation: slide 6s steps(2) infinite;
  }
  @keyframes slide{
    100%{
      top:-180px;
    }
  }
  .dynamic-txts1 span{
    margin-top: 10px;
  }
  .dynamic-txts1 span::after{
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background: #35455D;
    border-left: 0px solid;
    animation: typing 3s steps(26) infinite;
    animation-timing-function: linear;
  }
  @keyframes typing{
    100%{
      left: 100%;
      margin: 0 -35px 0 35px;
    }
  }
  .c2{
    /* font-size: 40px; */
    font-weight: 400;
    display: block;
    animation: typing;
    animation-play-state: paused
  }
  .static__txt{
    color: white;
    margin-left: 15px;
    margin-top: 100px;
  }
  .head__sec{
    height: 60vh;
  }
  @keyframes background-fade {
      99.9% {
          background: white
      }
      100% {
          background: lightblue;
      }
  }

  .about__container{
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg, 
        transparent, 
        var(--color-primary), 
        transparent
    );
    display: grid;
    place-items: center;
}

.about__me-image{
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(45deg);
    transition:  var(--transition);
}

.about__me-image:hover{
    transform: rotate(0);
}

.about__cards{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    color: white ;
}
.about__card{
    /* background: var(--color-bg-variant); */
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    color: white;
}

.about__card:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}
.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;

}
.about__card h5{
    font-size: 0.95rem;
}

.about__card small{
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p{
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
    color: white;
}
.p1{

}
.p2{

}

@media screen and (max-width: 600px) {
  header{
      height: 100vh;
  }
  .header__socials,
  .scroll__down{
      display: none;
  }
  .dynamic-txts li{
    font-size: 50px;
  }
  .dynamic-txts1 >li{
    font-size: 20px;
  }
  .t2{
    font-size: 17px;
  }
  .p1{
    width: 167px;
  }
  .p2{
    width: 300px;
  }
  .p3{
    display: none;
  }
}
